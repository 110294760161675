var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.value)?_c('div',{staticClass:"menu-popup__background"}):_vm._e()]),_c('transition',{attrs:{"name":"slide-right"}},[(_vm.value)?_c('div',{staticClass:"menu-popup"},[(_vm.value)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],class:{ 'menu-container': true, opened: _vm.value }},[_c('div',{staticClass:"menu-header"},[_c('i',{staticClass:"fa-solid fa-xmark fa-lg",staticStyle:{"margin-right":"20px"},on:{"click":_vm.close}})]),_c('div',{staticClass:"menu-body"},[(_vm.user)?_c('div',{staticClass:"menu-popup-user-role"},[_vm._v(" "+_vm._s(_vm.$t(("common." + (_vm.user.account_state.toLowerCase()))))+" ")]):_vm._e(),_c('Access',[_c('ul',{staticClass:"menu-popup-list"},[_c('transition',{attrs:{"name":"menu-left"}},[(_vm.tab.mode === 1)?_c('div',_vm._l((_vm.links),function(item,index){return _c('Access',{key:index,attrs:{"role":item.role}},[(item.children && item.visible)?_c('ListItem',{attrs:{"icon-class":item.iconClass,"title":_vm.$t(item.title),"new-notifications-counter":item.hasRedDotOnNotifications
                          ? _vm.notificationsCounter
                          : 0,"new-chat-messages-counter":item.hasRedDotOnMessages ? _vm.chatMessagesCounter : 0},on:{"click":function($event){_vm.tab = {
                          mode: 2,
                          type: item.type,
                          data: item.children
                        }}}}):(!item.external && item.visible)?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(item.to)}},[_c('ListItem',{attrs:{"icon-class":item.iconClass,"title":_vm.$t(item.title),"new-notifications-counter":item.hasRedDotOnNotifications
                            ? _vm.notificationsCounter
                            : 0,"new-chat-messages-counter":item.hasRedDotOnMessages ? _vm.chatMessagesCounter : 0},on:{"click":_vm.close}})],1):_vm._e()],1)}),1):_vm._e()]),_c('transition',{attrs:{"name":"menu-right"}},[(_vm.tab.mode === 2)?_c('div',[_vm._l((_vm.tab.data),function(item){return _c('div',{key:item.to},[(item.external)?_c('a',{ref:"noopener",refInFor:true,staticClass:"we-list__item",attrs:{"target":"_blank","href":item.to}},[_c('ListItem',{attrs:{"icon-class":item.iconClass,"title":_vm.$t(item.title)},on:{"click":_vm.close}})],1):_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(
                          _vm.tab.type !== 'more'
                            ? ("locations" + (item.to))
                            : item.to
                        )}},[_c('ListItem',{attrs:{"icon-class":item.iconClass
                            ? item.iconClass
                            : 'fa-regular fa-2xs fa-circle',"title":_vm.tab.type !== 'more' ? item.title : _vm.$t(item.title)},on:{"click":_vm.close}})],1)],1)}),(_vm.tab.type === 'cities')?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('locations')}},[_c('ListItem',{attrs:{"icon-class":"fa-solid fa-house-building","title":_vm.$t('header.link_label_other_cities')},on:{"click":_vm.close}})],1):_vm._e(),_c('WMButton',{attrs:{"small":"","color":"accent","type":"button","icon-class-name":"fa-solid fa-arrow-left fa-xs"},on:{"click":function($event){_vm.tab = { mode: 1, type: '', data: null }}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.back')))])])],2):_vm._e()])],1)]),_c('Access',{attrs:{"authorized":false}},[_c('div',{staticClass:"top not-authorized-menu"},[(!_vm.onFullScreen)?_c('div',{staticClass:"login"},[_c('section',[_c('h1',[_vm._v(_vm._s(_vm.$t('burger_menu.label_create_your_account')))])]),_c('router-link',{attrs:{"to":{
                    path: '/',
                    query: Object.assign({}, _vm.$route.query, {authPopup: 'sign-up'})
                  }}},[_c('WMButton',{attrs:{"small":"","icon-class-name":"fa-solid fa-plus fa-sm"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('header.button_label_signup')))])])],1),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('burger_menu.label_signin')))]),_c('router-link',{attrs:{"to":{
                    path: '/',
                    query: Object.assign({}, _vm.$route.query, {authPopup: 'login'})
                  }}},[_c('WMButton',{attrs:{"small":"","color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t('header.button_label_login'))+" ")])],1)],1):_vm._e(),_c('ul',{staticClass:"menu-popup-list"},[_c('transition',{attrs:{"name":"menu-left"}},[(_vm.tab.mode === 1)?_c('div',[_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(_vm.links[2].to)}},[_c('ListItem',{attrs:{"icon-class":_vm.links[2].iconClass,"title":_vm.$t(_vm.links[2].title)},on:{"click":_vm.close}})],1),_vm._l((_vm.links),function(item,index){return _c('div',{key:index},[(item.children && item.visible)?_c('ListItem',{attrs:{"icon-class":item.iconClass,"title":_vm.$t(item.title),"new-notifications-counter":item.hasRedDotOnNotifications
                            ? _vm.notificationsCounter
                            : 0,"new-chat-messages-counter":item.hasRedDotOnMessages ? _vm.chatMessagesCounter : 0},on:{"click":function($event){_vm.tab = {
                            mode: 2,
                            type: item.type,
                            data: item.children
                          }}}}):_vm._e()],1)})],2):_vm._e()]),_c('transition',{attrs:{"name":"menu-right"}},[(_vm.tab.mode === 2)?_c('div',[_vm._l((_vm.tab.data),function(item){return _c('div',{key:item.to},[(item.external)?_c('a',{ref:"noopener",refInFor:true,staticClass:"we-list__item",attrs:{"target":"_blank","href":item.to}},[_c('ListItem',{attrs:{"icon-class":item.iconClass,"title":_vm.$t(item.title)},on:{"click":_vm.close}})],1):_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(
                            _vm.tab.type !== 'more'
                              ? ("locations" + (item.to))
                              : item.to
                          )}},[_c('ListItem',{attrs:{"icon-class":item.iconClass
                              ? item.iconClass
                              : 'fa-regular fa-2xs fa-circle',"title":_vm.tab.type !== 'more' ? item.title : _vm.$t(item.title)},on:{"click":_vm.close}})],1)],1)}),(_vm.tab.type === 'cities')?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('locations')}},[_c('ListItem',{attrs:{"icon-class":"fa-solid fa-house-building","title":_vm.$t('header.link_label_other_cities')},on:{"click":_vm.close}})],1):_vm._e(),_c('WMButton',{attrs:{"small":"","color":"accent","type":"button","icon-class-name":"fa-solid fa-arrow-left fa-xs"},on:{"click":function($event){_vm.tab = { mode: 1, type: '', data: null }}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.back')))])])],2):_vm._e()])],1)])]),_c('transition',{attrs:{"name":"menu-left"}},[(_vm.tab.mode === 1)?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"submenu"},[_c('Access',{attrs:{"role":_vm.UserRoleState.Booker}},[_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('vendor')}},[_c('ListItem',{attrs:{"icon-class":"fa-solid fa-building","title":_vm.$t('header.link_label_host').toString(),"border":false},on:{"click":_vm.close}})],1)],1),_c('Access',{attrs:{"authorized":false}},[_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath('vendor')}},[_c('ListItem',{attrs:{"icon-class":"fa-solid fa-building","title":_vm.$t('header.link_label_host').toString(),"border":false},on:{"click":_vm.close}})],1)],1),_c('Access',{attrs:{"authorized":true}},[_c('ListItem',{staticClass:"logout-button",attrs:{"title":_vm.$t('profile.side_menu.log_out'),"icon-class":"fa-solid fa-right-from-bracket","border":false},on:{"click":_vm.logout}})],1)],1)]):_vm._e()])],1)]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }